//Google fonts
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Raleway&display=swap');


//Changing fonts
$font-family-sans-serif: 'Raleway', sans-serif !default;
$font-family-serif:     'Playfair Display', serif !default;
$font-family-base:       $font-family-sans-serif !default;
$headings-font-family: $font-family-serif !default;
$headings-font-weight: 700 !default;


$primary: #04ADBF;
$secondary: #03738C;
$danger: #A62F03;
$light: #f7f7f7;


//import bootstrap
@import '~bootstrap/scss/bootstrap.scss'